import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Col, Row, Grid } from "react-native-easy-grid";
import { styles } from '../../styles';
import { SafeAreaView } from 'react-native-safe-area-context';

class ReportNoneBSPComponent extends React.Component {
  constructor(props:any) {
    super(props);
    this.state = {
      name: "ReportNoneBSPComponent",
      isLoading: true,
    }
  }
  render() {    
    //console.log('this.props.datas=',this.props.datas);
    // let totalFare:number = 0.0;
    // let totalNetFare:number = 0.0;
    // let totalTaxes:number = 0.0;
    // let totalTotalFare:number = 0.0;
    // let totalComm:number = 0.0;
    // let totalCancellationFee:number = 0.0;
    if (this.props.datas === null
      || this.props.datas.length === 0)
    {
      return <View></View>
    }

    let header = (<Row key={'header'} style={styles.gridHeadRow}>
      <Col><Text style={styles.gridHeaderText}>No.</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Tkt code</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Ticket Number</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Type</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Document Status Code</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Owner PCC</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Owner Agent</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Agent Issue PCC</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Agent Issue Name</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Class</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Pax Name</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Itinerary</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Ticket Exchange Information</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Indicator</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Group</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Issue Date</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Currency</Text></Col>      
      <Col><Text style={styles.gridHeaderText}>Booking Date</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Booking SignOn</Text></Col>
      <Col><Text style={styles.gridHeaderText}>PNR Code</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Date Of Payment</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Form Of Payment</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Place Of Payment</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Remark</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Phone</Text></Col>
      <Col><Text style={styles.gridHeaderText}>Email</Text></Col>
    </Row>);
    let no = 0;
    let body = this.props.datas.map((item:any) => {
      no++;
      // totalFare += Number(item.fare);
      // totalNetFare += Number(item.netFare);
      // totalTaxes += Number(item.taxes);
      // totalTotalFare += Number(item.totalFare);
      // totalComm += Number(item.comm);
      // totalCancellationFee += Number(item.cancellationFee);
      return (
        <Row key={no} style={no%2==0 ? styles.gridEvenRow : styles.gridOddRow}>
          <Col><Text>{no}</Text></Col>
          <Col><Text>{item.tktCode}</Text></Col>
          <Col><Text>{item.ticketNumber}</Text></Col>
          <Col><Text>{item.type}</Text></Col>
          <Col><Text>{item.documentStatusCode}</Text></Col>
          <Col><Text>{item.ownerPcc}</Text></Col>
          <Col><Text>{item.ownerAgent}</Text></Col>
          <Col><Text>{item.agentIssuePcc}</Text></Col>
          <Col><Text>{item.agentIssueName}</Text></Col>
          <Col><Text>{item.class}</Text></Col>
          <Col><Text>{item.paxName}</Text></Col>
          <Col><Text>{item.itinerary}</Text></Col>
          <Col><Text>{item.ticketExchangeInfo}</Text></Col>
          <Col><Text>{item.indicator}</Text></Col>
          <Col><Text>{item.group}</Text></Col>
          <Col><Text>{item.issueDate}</Text></Col>
          <Col><Text>{item.currency}</Text></Col>          
          <Col><Text>{item.bookingDate}</Text></Col>
          <Col><Text>{item.bookingSignOn}</Text></Col>
          <Col><Text>{item.pnrCode}</Text></Col>
          <Col><Text>{item.dateOfPayment}</Text></Col>
          <Col><Text>{item.formOfPayment}</Text></Col>
          <Col><Text>{item.placeOfPayment}</Text></Col>
          <Col><Text>{item.remark}</Text></Col>
          <Col><Text>{item.phone}</Text></Col>
          <Col><Text>{item.email}</Text></Col>
        </Row>
        );
    });    
    var footer = (<Row key={'footer'} style={styles.gridTotalRow}>
      <Col><Text></Text></Col>
      <Col><Text style={styles.textBold}>Total</Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>      
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>      
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>
      <Col><Text></Text></Col>      
    </Row>);
    return (
    <View>
      <Grid style={styles.gridBody}>
        {header}
        {body}
        {footer}
      </Grid>
    </View>);
  }
}

export default ReportNoneBSPComponent;