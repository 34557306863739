import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { GetCurrentUser } from "../../services/dataservice";
import ReportBSPComponent from '../report-bsp';
import ReportNoneBSPComponent from '../report-nonebsp';

class ReportComponent extends React.Component {
  constructor(props:any) {
    super(props);
    this.state = {
      name: "ReportComponent",
      isLoading: true,
      username: '',
      userRole: '',
      newUserRole: '',
      canExportPcc: '',
      ownerPcc: '',
      agentName: '',
      iataNumber: '',
    }
  }
  render() {    
    //console.log('this.props.datas=',this.props.datas);
    const { username, iataNumber } = this.state;

    console.log('username=', username, 'iataNumber=', iataNumber);

    if (this.props.datas === null
      || this.props.datas.length === 0)
    {
      return <View></View>
    }
    if(iataNumber === null
      || iataNumber === '' 
      || iataNumber.length < 7) {
      return (
        <View>
          <ReportNoneBSPComponent datas={this.props.datas} />  
        </View>);
    }

    return (
    <View>
      <ReportBSPComponent datas={this.props.datas} />  
    </View>);
  }
  componentDidMount() {
    GetCurrentUser().then((data) => {
      //console.log("data=", data);
      this.setState({        
        username: data.username,
        userRole: data.role,
        newUserRole: data.role,        
        canExportPcc: data.canExportPcc,
        ownerPcc: data.ownerPcc,
        agentName: data.agentName,
        iataNumber: data.iataNumber,
      })
    })
    .finally(() => {
      //this.loaded();
    });
  }
}

export default ReportComponent;